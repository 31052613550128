import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { connect } from 'react-redux'

import {
    deviceDetails,
    deviceMismatch,
    showModal,
    webSerialConnect,
} from '../../../redux/actions'
import { readBytes, sendBytes } from '../../ReusableComponents/ByteTransfer'

import renderImage from '../../../source/importImg'
import style from './MainHeader.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/free-solid-svg-icons'
import {
    connectSerialDevice,
    connectSerialDeviceInitial,
    connectSerialDeviceIntermediate,
    connectUSBDevice,
    openSerialReadComPort,
} from '../../WebSerialConnection/SerialUSBConnect'
import cacheAssets from '../../../utils/cacheAssets'
import { decrypt, generateBrowserFingerprint } from '../../Login/JWTverify'
import DialogModal from '../DialogModal/DialogModal'
import DeviceMissMatchPopupCom from './DeviceMissMatchPopupCom'

const importIcon = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-upload"
        viewBox="0 0 16 16"
    >
        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
        <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
    </svg>
)

function MainHeader({
    webSerial,
    worker,
    webSerialConnect,
    title,
    helper,
    showBluetoothBtn,
    titlePosition,
    help_btnPosition,
    showImport = false,
    handleImport,
    goBack,
    isGoBack,
    showUserAccount,
    showAccountSetting,
    deviceMismatch,
    device,
    deviceDetail,
    DeselectedComponent,
    concept,
}) {
    const history = useHistory()
    const location = window.location
    const [isHelp, setHelp] = useState(false)
    const [isDeviceMismatch, setIsDeviceMismatch] = useState(false)
    const [showPopup, setShowPopup] = useState(false)
    const [showErrorMsg, setshowErrorMsg] = useState(false)
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    const deviceVersion = sessionStorage.getItem('deviceVersion')

    let deviceName = ''
    switch (connectedDevice) {
        case 'Hexapod':
            deviceName = 'Crawl-e'
            break
        default:
            deviceName = 'Zing'
    }

    const handleLogout = () => {
        // Perform logout action here
        setShowPopup(false)
        history.push('/login')
        localStorage.removeItem('userJWT')
        localStorage.removeItem('atatus-user')
    }

    const handleClick = () => {
        const pcVersion = sessionStorage.getItem('deviceVersion')
        const connectedDevice = sessionStorage.getItem('connectedDevice')
        // if (connectedDevice == 'Ace' && pcVersion === '0.1.18') {
        //     history.push('/advanceSettingv0.1')
        // } else if (
        //     (connectedDevice == 'Ace' && pcVersion?.startsWith('1')) ||
        //     ['Roboki'].includes(connectedDevice)
        // ) {
        history.push('/advanceSettingv1.0')
        // } else {
        //     history.push('/advanceSettingZingv0.1')
        // }
    }

    const handleHelpBtn = () => {
        setHelp(!isHelp)
    }

    const requestConnection = async (e) => {
        if (!webSerial.isConnected) {
            let res = await connectSerialDeviceIntermediate(
                { isAutomatic: false },
                worker
            )
            if (res !== undefined) {
                if (!res.isPortOpen) {
                    deviceMismatch({
                        deviceMismatched: res.matchedObj_with_Version,
                        isMemoryPresent: res.isMemoryPresent,
                    })
                    setIsDeviceMismatch(true)
                } else {
                    setIsDeviceMismatch(false)
                    deviceMismatch({
                        deviceMismatched: {},
                        isMemoryPresent: res.isMemoryPresent,
                    })
                }
            }
            // port = port['port']

            // if (e.data.type == 'connected' && e.data.value) {
            if (res) webSerialConnect({ port: res.port })
            else setshowErrorMsg(true)
        }
    }
    //for mismatch of device while connecting
    const handleDevice = async (option) => {
        if (option === 'no') {
            let port = webSerial.port
            //is this needed??
            // await openSerialReadComPort(port,worker)
        } else {
            shouldErase()
            history.push('/Selection')
            let mismatchedDevice = device.deviceMismatched
            console.log(mismatchedDevice)
            sessionStorage.setItem('connectedDevice', mismatchedDevice.id)
            // if (
            //     mismatchedDevice.id === 'Ace' ||
            //     mismatchedDevice.id === 'Hexapod'
            // ) {
            sessionStorage.setItem('deviceVersion', mismatchedDevice.version)
            deviceDetail({
                connectedDevice: mismatchedDevice.id,
                deviceVersion: mismatchedDevice.version,
            })
            // } else {
            //     sessionStorage.setItem('zingVersion', mismatchedDevice.version)
            //     deviceDetail({
            //         connectedDevice: mismatchedDevice.id,
            //         zingVersion: mismatchedDevice.version,
            //         deviceVersion: '0.0.0',
            //     })
            // }
        }
        setIsDeviceMismatch(false)
        deviceMismatch({ deviceMismatched: {} })
    }
    //when device is disconnect set the mismatch empty
    useEffect(() => {
        if (sessionStorage.getItem('isDeviceConnected') == 'false') {
            setIsDeviceMismatch(false)
            deviceMismatch({ deviceMismatched: {} })
        }
    }, [webSerial.isConnected])

    const shouldErase = () => {
        let programMode = sessionStorage.programMode
        let connectedDevice = sessionStorage.connectedDevice
        let version = sessionStorage.deviceVersion
        let recoveryData = JSON.parse(localStorage.getItem('recoveryData'))
        if (
            localStorage.getItem('recoveryData') !== null &&
            programMode in recoveryData &&
            connectedDevice in recoveryData[programMode] &&
            version in recoveryData[programMode][connectedDevice]
        ) {
            if (Object.keys(recoveryData).length === 1) {
                if (Object.keys(recoveryData[programMode]).length === 1) {
                    if (
                        Object.keys(recoveryData[programMode][connectedDevice])
                            .length === 1
                    ) {
                        localStorage.removeItem('recoveryData')
                    } else {
                        delete recoveryData[programMode][connectedDevice][
                            version
                        ]
                        localStorage.setItem(
                            'recoveryData',
                            JSON.stringify(recoveryData)
                        )
                    }
                } else {
                    if (
                        Object.keys(recoveryData[programMode][connectedDevice])
                            .length === 1
                    ) {
                        delete recoveryData[programMode][connectedDevice]
                    } else {
                        delete recoveryData[programMode][connectedDevice][
                            version
                        ]
                    }
                    localStorage.setItem(
                        'recoveryData',
                        JSON.stringify(recoveryData)
                    )
                }
            } else {
                if (Object.keys(recoveryData[programMode]).length === 1) {
                    if (
                        Object.keys(recoveryData[programMode][connectedDevice])
                            .length === 1
                    ) {
                        delete recoveryData[programMode]
                    } else {
                        delete recoveryData[programMode][connectedDevice][
                            version
                        ]
                    }
                    localStorage.setItem(
                        'recoveryData',
                        JSON.stringify(recoveryData)
                    )
                } else {
                    if (
                        Object.keys(recoveryData[programMode][connectedDevice])
                            .length === 1
                    ) {
                        delete recoveryData[programMode][connectedDevice]
                    } else {
                        delete recoveryData[programMode][connectedDevice][
                            version
                        ]
                    }
                    localStorage.setItem(
                        'recoveryData',
                        JSON.stringify(recoveryData)
                    )
                }
            }
        }
        sessionStorage.removeItem('programEnd')
        sessionStorage.setItem('pip', false)
        sessionStorage.setItem('name', 'null')
        sessionStorage.removeItem('Index')
        sessionStorage.setItem('shield', 'false')
        sessionStorage.setItem('peeCeeATR', 'false')
        sessionStorage.setItem('arm_shield', 'false')
        sessionStorage.removeItem('PET')
        sessionStorage.removeItem('EndSwitch')
        concept.Index = []
        // window.location.reload(false)
        DeselectedComponent({
            Four_in_one_sensor: {
                isDistanceSensors: false,
                isGestureSensor: false,
                isLightSensor: false,
                isColorSensor: false,
            },
            isFour_in_one_sensor: false,

            isMic: false,
            isTemperature: false,

            // input
            isTouchZero: false,
            isTouchOne: false,
            isTouchTwo: false,

            // output
            isTouchZeroOutput: false,
            isTouchOneOutput: false,
            isTouchTwoOutput: false,

            isLeftEye: false,
            isRightEye: false,
            isbuzzer: false,

            isSmileOne: false,
            isSmileTwo: false,
            isSmileThree: false,
            isSmileFour: false,
        })
    }
    //handle error in connection
    const handleError = async () => {
        setshowErrorMsg(false)
        window.location.reload()
    }
    useEffect(() => {
        if (device) {
            let deviceD = Object.keys(device.deviceMismatched)
            if (deviceD.length > 1) {
                if (isDeviceMismatch == false) {
                    setIsDeviceMismatch(true)
                }
            }
        }
    }, [device.deviceMismatched])

    const [googleUserData, setGoogleUserData] = useState({})

    const getDataFromLocalStorage = () => {
        return new Promise((resolve, reject) => {
            let userAccount = localStorage.getItem('userJWT')

            if (userAccount) {
                resolve(userAccount ?? null)
            }
        })
    }

    useEffect(async () => {
        const visitorId = await generateBrowserFingerprint()
        const userJWT = await getDataFromLocalStorage()
        if (!userJWT) return
        const verify = await decrypt(userJWT, visitorId)
        if (verify) {
            const userData = {
                userAccount_email: verify.email,
                userAccount_name: verify.name,
                userAccount_Img: verify.image,
            }
            setGoogleUserData(userData)
        }
    }, [])

    useEffect(() => {
        let assetsToBeCached = [
            renderImage('backBtn'),
            renderImage('clos'),
            renderImage('helpBtnInActive'),
            renderImage('UsbOn'),
            renderImage('UsbOff'),
        ]
        cacheAssets(assetsToBeCached)
    }, [])

    const [imageError, setImageError] = useState(false)

    const handleImageError = () => {
        setImageError(true)
    }

    return (
        <>
            <DialogModal
                mismatchShow={isDeviceMismatch}
                text={<DeviceMissMatchPopupCom />}
                showCloseBtn={true}
                handleDialogModalClose={() => setIsDeviceMismatch(false)}
                optionsToSelect={[
                    {
                        text: 'YES',
                        do: () => handleDevice('yes'),
                    },
                    // {
                    //     text: 'NO',
                    //     do: () => handleDevice('no'),
                    // },
                ]}
            />
            <DialogModal
                mismatchShow={
                    connectedDevice == 'Humanoid' &&
                    deviceVersion?.startsWith('2') &&
                    webSerial.isConnected &&
                    !isDeviceMismatch &&
                    !device.isMemoryPresent
                }
                text={`There is no memory card inserted in ${deviceName}. \nkindly insert one to use the application further.`}
                showCloseBtn={false}
                optionsToSelect={[
                    {
                        text: 'YES',
                        do: () => history.push('/kitSelection'),
                    },
                ]}
            />
            <DialogModal
                showError={showErrorMsg}
                text={
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <p
                            style={{
                                transform: 'translateY(20%)',
                                fontSize: '2.5vh',
                            }}
                        >
                            Error in connecting the device!
                        </p>
                        <p
                            style={{
                                transform: 'translateY(20%)',
                                fontSize: '2vh',
                            }}
                        >
                            Please disconnect the device and try connecting
                            again.
                        </p>
                    </div>
                }
                showCloseBtn={true}
                handleDialogModalClose={() => setshowErrorMsg(false)}
                optionsToSelect={[
                    {
                        text: 'OK',
                        do: handleError,
                    },
                ]}
            />
            <div className={style.container}>
                <div>
                    {isGoBack || goBack ? (
                        //to prevent user from going back in manual selection- location.pathname=='/biboxSelection'
                        <img
                            className={
                                webSerial.isConnected &&
                                (location.pathname == '/Selection' ||
                                    location.pathname == '/selection' ||
                                    location.pathname == '/biboxSelection')
                                    ? `${style.connected_back_btn}`
                                    : `${style.back_btn}`
                            }
                            src={renderImage('backBtn')}
                            onClick={async () => {
                                if (goBack) {
                                    goBack()
                                } else history.goBack()
                            }}
                        ></img>
                    ) : null}
                </div>
                <div>
                    <p
                        className={style.title}
                        style={
                            titlePosition === 'center' ? { left: '43%' } : null
                        }
                    >
                        {title}
                    </p>
                </div>
                {showImport && (
                    <div>
                        <label className={style.importBtn}>
                            <FontAwesomeIcon icon={faUpload} />
                            <input
                                type="file"
                                style={{ display: 'none' }}
                                accept=".ag"
                                onChange={(evt) => {
                                    handleImport(evt)
                                }}
                            ></input>
                        </label>
                    </div>
                )}
                <div>
                    {isHelp == false && helper ? (
                        <div
                            className="header_button"
                            // style={{ position: 'relative' }}
                        >
                            <img
                                className={style.help_btn}
                                style={
                                    help_btnPosition === 'right'
                                        ? { right: '4%' }
                                        : null
                                }
                                src={renderImage('helpBtnInActive')}
                                onClick={handleHelpBtn}
                            ></img>
                            <span
                                className="tooltiptext"
                                style={
                                    help_btnPosition === 'right'
                                        ? { left: '94%' }
                                        : { left: '92%' }
                                }
                            >
                                {' '}
                                <p>Help</p>
                            </span>
                        </div>
                    ) : (
                        <div className={style.help_slider}>{helper}</div>
                    )}

                    {isHelp ? (
                        <div>
                            <img
                                className={style.cross}
                                src={renderImage('clos')}
                                onClick={handleHelpBtn}
                            ></img>
                        </div>
                    ) : null}
                </div>

                {showUserAccount && Object.keys(googleUserData).length ? (
                    <div className={style.help_Body}>
                        {/* USER PROFILE IMAGE */}
                        <img
                            className={style.userAccount_Btn}
                            style={{
                                display: imageError ? 'none' : 'block',
                            }}
                            src={googleUserData.userAccount_Img}
                            onClick={() => setShowPopup(!showPopup)}
                        />

                        {showPopup && (
                            <div className={style.popupCard}>
                                <div
                                    style={{
                                        width: '310px',
                                        height: '150px',
                                        background: '#FFFFFF',
                                        borderRadius: '32px 32px 0px 0px ',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        padding: '10px',
                                        columnGap: '4%',
                                    }}
                                >
                                    <div>
                                        <img
                                            style={{
                                                width: '55px',
                                                borderRadius: '29px',
                                                display: imageError
                                                    ? 'none'
                                                    : 'block',
                                            }}
                                            src={googleUserData.userAccount_Img}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <p>{googleUserData.userAccount_name}</p>
                                        <p
                                            style={{
                                                marginTop: '-10px',
                                                color: 'grey',
                                            }}
                                        >
                                            {' '}
                                            {googleUserData.userAccount_email}
                                        </p>
                                    </div>
                                </div>

                                <div
                                    style={{
                                        marginTop: '7px',
                                        width: '310px',
                                        height: '123px',
                                        background: '#FFFFFF',
                                        // border: '2px solid blue',
                                        borderRadius: '0px 0px 32px 32px ',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <button onClick={handleLogout}>
                                        {/* {'[->'} */}
                                        Sign out Your Account
                                    </button>
                                </div>
                            </div>
                        )}

                        <span className={style.tooltiptext}>
                            <p style={{ color: ' #E4E5E4' }}>Google Account</p>
                            <p>{googleUserData.userAccount_name}</p>
                            <p> {googleUserData.userAccount_email}</p>
                        </span>
                    </div>
                ) : null}

                {showAccountSetting && (
                    <div className={style.help_Body}>
                        {/* USER PROFILE IMAGE */}
                        <div className="header_button">
                            <img
                                className={style.userAccount_settingBtn}
                                src={renderImage('activeAdvanceSetting')}
                                onClick={handleClick}
                            />
                            <span
                                className="tooltiptext"
                                style={{ left: '88.3%' }}
                            >
                                {' '}
                                <p>Setting</p>
                            </span>
                        </div>
                    </div>
                )}

                {showBluetoothBtn && (
                    <div className="web_button">
                        <img
                            className={style.bluetooth_Btn}
                            src={
                                webSerial.isConnected
                                    ? renderImage('UsbOn')
                                    : renderImage('UsbOff')
                            }
                            onClick={requestConnection}
                        ></img>
                        <span
                            className="tooltiptext"
                            style={{ left: '96%', top: '17vh' }}
                        >
                            {' '}
                            <p>
                                Device <br />
                                connectivity
                            </p>
                        </span>
                    </div>
                )}
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return state
}
const mapDispatchToProps = (dispatch) => {
    return {
        webSerialConnect: (data) => {
            dispatch(webSerialConnect(data))
        },
        showModal: (data) => {
            dispatch(showModal(data))
        },
        deviceMismatch: (data) => {
            dispatch(deviceMismatch(data))
        },
        deviceDetail: (data) => {
            dispatch(deviceDetails(data))
        },
        DeselectedComponent: (data) => {
            dispatch({ type: 'INTERNAL_ACCESSORIES', payload: data })
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MainHeader)
